import { useState } from "react";

//styles
import stylesTemplate from "./Template.module.css";

export const Template_MoonVaultEmbed = (props) =>
{
    return (
        <div>
            <main className={stylesTemplate.main}>
                {props.children}
            </main>
        </div>
    );
};
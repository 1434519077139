//components
import
{
    Grid,
    Flex
} from "@MoonLabsDev/dapp-core-lib";
import
{
    MoonSwapTradeInfo,
    MoonSwapTradeWindow
} from "@MoonVault/dapp-widgets-lib";
import
{
	MVPage,
	MVCheckModule
} from "../../dApp/components";

const MoonSwapContent = () =>
{
    return (
        <Flex style={{ width: "100%"}}>
            <Grid
                style=
                {
                    {
                        flexGrow: 1,
						alignItems: "start"
                    }
                }
                responsive=
                {
                    {
                        lg:
                        {
                            cols: 2
                        }
                    }
                }
            >
                <MoonSwapTradeWindow />
                <MoonSwapTradeInfo
                    groupByRouter={true}
                />
            </Grid>
        </Flex>
    );
};

export const Page_swap = () =>
{
	return (
		<MVPage>
            <MVCheckModule module="moon_swap">
				<MoonSwapContent />
			</MVCheckModule>
        </MVPage>
    );
}
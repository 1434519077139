import React from "react";
import ReactDOM from "react-dom";

import { AppEmbed } from "./dApp/components/AppEmbed";

ReactDOM.render(
	<React.StrictMode>
		<AppEmbed />
	</React.StrictMode>,
	document.getElementById("root")
);

//dApp
import { configPage as config } from "@MoonVault/dapp-widgets-lib";

//pages (test)
import
{
	Page_debugInfo
} from "@MoonLabsDev/dapp-sdk-lib";

//pages
import
{
	Page_swap,
	Page_earn_vaults,
	Page_earn_vaultDetails,
	Page_tools_escrow,
} from "../../pages";

const devMode = false;
const configPage =
{
	...config,

	themeColor: "#000000",
	logo: "/assets/page/logo.png",
	fetchDataURL: `https://${devMode ? "dev" : "app"}.moon-vault.com/api.php`,
	devMode: devMode,
	preconnect:
    [
        { link: "https://fonts.googleapis.com" },
        { link: "https://fonts.gstatic.com", crossOrigin: true }
    ],
	fonts:
    [
		"https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap"
    ],
    pages:
    [
        { path: "debugInfo", page: Page_debugInfo },

		{ path: "home", page: Page_debugInfo },

        { path: "swap", page: Page_swap },

        { path: "earn/vaults", page: Page_earn_vaults },
		{ path: "earn/vaultDetails", page: Page_earn_vaultDetails },

        { path: "tools/escrow", page: Page_tools_escrow }
    ]
};

export default configPage;
import { configUI as configUI_sdk } from "@MoonLabsDev/dapp-sdk-lib";

//template & theme
import { Template_MoonVaultEmbed } from "../../templates/MoonVaultEmbed";
import { Theme_MoonVaultEmbed } from "../../themes/MoonVaultEmbed";

const configUI =
{
	...configUI_sdk,

    template: Template_MoonVaultEmbed,
	theme: Theme_MoonVaultEmbed
};

export default configUI;
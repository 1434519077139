import { useEffect } from "react";

//framework
import { App } from "@MoonLabsDev/dapp-sdk-lib";

//config
import { config } from "../config";

const getWallet = () =>
{
	const searchParams = new URLSearchParams(window?.location?.search);
	return (searchParams.has("wallet")
		? searchParams.get("wallet")
		: null
	);
}

const getThemeOverrideValue = (_name) =>
{
	const searchParams = new URLSearchParams(window?.location?.search);
	return (searchParams.has(_name)
		? searchParams.get(_name)
		: undefined
	);
};

const getThemeOverride = () =>
{
	return {
		text:
		{
			primary: getThemeOverrideValue("text.primary"),
			secondary: getThemeOverrideValue("text.secondary"),
			accent: getThemeOverrideValue("text.accent")
		},
		card:
		{
			header:
			{
				bg: getThemeOverrideValue("card.header.bg")
			},
			body:
			{
				bg: getThemeOverrideValue("card.body.bg")
			},
			footer:
			{
				bg: getThemeOverrideValue("card.footer.bg")
			}
		},
		button:
		{
			primary:
			{
				bg: getThemeOverrideValue("button.primary.bg"),
				color: getThemeOverrideValue("button.primary.color")
			},
			secondary:
			{
				bg: getThemeOverrideValue("button.secondary.bg"),
				color: getThemeOverrideValue("button.secondary.color")
			},
			cancel:
			{
				bg: getThemeOverrideValue("button.cancel.bg"),
				color: getThemeOverrideValue("button.cancel.color")
			}
		}
	};
};

export const AppEmbed = ({children}) =>
{
	//functions
	const setProp = (_name, _value) =>
	{
		if (_value === undefined)
		{
			return;
		}
		if (Array.isArray(_name))
		{
			_name.forEach(n => setProp(n, _value));
			return;
		}
		document.documentElement.style.setProperty(_name, _value);
	};

	//effects
	useEffect(() =>
	{
		const override = getThemeOverride();

		//text
		setProp("--ML_page_colorPrimary", override.text?.primary);
		setProp("--ML_page_colorSecondary", override.text?.secondary);
		setProp("--ML_page_colorAccent", override.text?.accent);

		//card
		setProp("--ML_card_header_background", override.card?.header?.bg);
		setProp("--ML_card_body_background", override.card?.body?.bg);
		setProp("--ML_card_footer_background", override.card?.footer?.bg);

		//button
		setProp(
			[
				"--ML_button_backgroundPrimary",
				"--ML_button_border_colorPrimary",
				"--ML_button_hover_backgroundPrimary"
			],
			override.button?.primary?.bg
		);
		setProp("--ML_button_colorPrimary", override.button?.primary?.color);
		setProp(
			[
				"--ML_button_background",
				"--ML_button_border_color",
				"--ML_button_hover_background"
			],
			override.button?.secondary?.bg
		);
		setProp("--ML_button_color", override.button?.secondary?.color);
		setProp(
			[
				"--ML_button_backgroundCancel",
				"--ML_button_border_colorCancel",
				"--ML_button_hover_backgroundCancel"
			],
			override.button?.cancel?.bg
		);
		setProp("--ML_button_colorCancel", override.button?.cancel?.color);

		//toolButton
		setProp("--ML_toolButton_color", override.button?.secondary?.color);
		setProp("--ML_toolButton_border_color", override.button?.secondary?.bg);
	}, []);

	return (
		<App
			config={config}
			wallet={getWallet()}
		>
			{children}
		</App>
	);
}
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ResponsiveContainer } from "recharts";

//components
import
{
	Button,
	Card,
	CardBody,
	CardHeader,
	Flex,
	Grid,
	Text,
	TextToggle
} from "@MoonLabsDev/dapp-core-lib";
import
{
	VaultV1Details,
	LineChart_VaultV1APR,
	LineChart_VaultV1TokenPrice,
	VaultV1Deposit,
	VaultV1Withdraw,
	LineChart_VaultV1UserHistory,
	ButtonVaultV1Compound,
	useMoonAPI
} from "@MoonVault/dapp-widgets-lib";
import
{
	MVPage,
	MVCheckModule
} from "../../dApp/components";

//framework
import { useEventSubscription, MLUtils } from "@MoonLabsDev/dapp-core-lib";

//modules
import { useVaultV1, ModuleEvents_VaultV1 } from "@MoonVault/dapp-widgets-lib";

//styles
import styles from "../Pages.module.css";

const CardAdmin = ({vaultID}) =>
{
	return (
		<Card>
			<CardHeader>
				<Flex>
					<Text
						size={0}
						color={1}
					>
						Admin
					</Text>
				</Flex>
			</CardHeader>
			<CardBody>
				<ButtonVaultV1Compound
					vaultID={vaultID}
					showReward={true}
				/>
			</CardBody>
		</Card>
	);
};

const ChardChart_TokenPrice = ({vaultID}) =>
{
	return (
		<Card className={styles.chart}>
			<CardBody>
				<Grid>
					<Flex
						justify="space-between"
						style={{ height: "42px" }}
					>
						<Text>
							Token Price:
						</Text>
					</Flex>
					<div style={{ width: "100%", height: 200 }}>
						<ResponsiveContainer>
							<LineChart_VaultV1TokenPrice
								vaultID={vaultID}
								width={500}
								height={200}
							/>
						</ResponsiveContainer>
					</div>
				</Grid>
			</CardBody>
		</Card>
	);
};

const CardChart_APR = ({vaultID}) =>
{
	//state
	const [showSum, setShowSum] = useState(() => false);

	return (
		<Card className={styles.chart}>
			<CardBody>
				<Grid>
					<Flex
						justify="space-between"
						style={{ height: "42px" }}
					>
						<Text>
							Historic Daily APR:
						</Text>

						<TextToggle
							values=
							{
								[
									{ text: "Daily", showSum: false },
									{ text: "Sum", showSum: true }
								]
							}
							selectedIndex={(showSum ? 0 : 1)}
							onChange={(v, i) => setShowSum(v.showSum)}
						/>
					</Flex>
					<div style={{ width: "100%", height: 200 }}>
						<ResponsiveContainer>
							<LineChart_VaultV1APR
								vaultID={vaultID}
								sum={showSum}
								width={500}
								height={200}
							/>
						</ResponsiveContainer>
					</div>
				</Grid>
			</CardBody>
		</Card>
	);
};

const CardChart_User = ({vaultID}) =>
{
	//state
	const [showUSD, setShowUSD] = useState(() => true);

	return (
		<Card className={styles.chart}>
			<CardBody>
				<Grid>
					<Flex
						justify="space-between"
						style={{ height: "42px" }}
					>
						<Text>
							User TVL:
						</Text>

						<TextToggle
							values=
							{
								[
									{ text: "USD", showUSD: true },
									{ text: "Token", showUSD: false }
								]
							}
							selectedIndex={(showUSD ? 0 : 1)}
							onChange={(v, i) => setShowUSD(v.showUSD)}
						/>
					</Flex>
					<div style={{ width: "100%", height: 200 }}>
						<ResponsiveContainer>
							<LineChart_VaultV1UserHistory
								vaultID={vaultID}
								width={500}
								height={200}
								showUSD={showUSD}
							/>
						</ResponsiveContainer>
					</div>
				</Grid>
			</CardBody>
		</Card>
	);
};

const VaultDetailsContent = ({vaultID, onGoBackToVaults}) =>
{
	//context
	const api = useMoonAPI();
	const vault = useVaultV1();

	//functions
	const getVaultData = (_vault) =>
	{
		return {..._vault};
	};

	//state
	const [vaultData, setVaultData] = useState(() => getVaultData(vault.findVault(vaultID)));

	//effects
	useEffect(() =>
    {
		setVaultData(getVaultData(vault.findVault(vaultID)));
    }, [vaultID]);
	useEventSubscription(
		[
			ModuleEvents_VaultV1.vaultData,
			ModuleEvents_VaultV1.userData,
			ModuleEvents_VaultV1.dbData
		],
		() => setVaultData(getVaultData(vault.findVault(vaultID))),
		[vaultID]
	);

	return (
		<>
			{!!vaultData.initialized &&
				<>
					<VaultV1Details
						vault={vaultData}
						onGoBack={onGoBackToVaults}
					/>

					<Grid
						cols={1}
						responsive=
						{
							{
								md:
								{
									cols: 2
								}
							}
						}
					>
						<VaultV1Deposit
							vaultID={vaultData.id}
						/>

						<VaultV1Withdraw
							vaultID={vaultData.id}
						/>
					</Grid>

					<Grid
						cols={1}
						responsive=
						{
							{
								md:
								{
									cols: 2
								}
							}
						}
					>
						<ChardChart_TokenPrice vaultID={vaultData.id} />
						<CardChart_APR vaultID={vaultData.id} />
					</Grid>

					<CardChart_User vaultID={vaultData.id} />

					{!!api?.isDeveloper() &&
						<CardAdmin vaultID={vaultData.id} />
					}
				</>
			}
		</>
	);
};

export const Page_earn_vaultDetails = () =>
{
	//context
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();

	//functions
	const getVaultID = (_searchParams) =>
	{
		if (!searchParams.has("vault"))
		{
			return null;
		}

		return MLUtils.defaultNumber(parseInt(_searchParams.get("vault")), null);
	};

    //state
    const [vaultID, setVaultID] = useState(() => getVaultID(searchParams));

	//handler
	const onGoBackToVaults = (_vaultID) =>
	{
		if (_vaultID === null)
		{
			navigate(`/earn/vaults`);
		}
		else
		{
			navigate(`/earn/vaults?#vault_${_vaultID}`);
		}
	};

    //effects
    useEffect(() =>
    {
		if (searchParams.has("vault"))
		{
			setVaultID(getVaultID(searchParams));
		}
    }, [searchParams]);

    return (
		<MVPage>
            <MVCheckModule module="moon_vaultV1">
				{vaultID === null
					? <Button onClick={() => onGoBackToVaults(vaultID)}>
						Back to Vaults
					</Button>
					: <VaultDetailsContent
						vaultID={vaultID}
						onGoBackToVaults={onGoBackToVaults}
					/>
				}
			</MVCheckModule>
        </MVPage>
    );
}
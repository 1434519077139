const configMenu =
{
	desktopMenu:
	[
		{
			text: "Swap",
			link: "/swap",
		},
		{
			text: "Earn",
			link: "/earn/vaults",
			children:
			[
				{
					text: "Vaults",
					link: "/earn/vaults"
				}
			]
		},
		{
			text: "Tools",
			link: "/tools/escrow",
			children:
			[
				{
					text: "Escrow",
					link: "/tools/escrow"
				}
			]
		}
	],

	tabletMenu: undefined, //will use desktop instead

	mobileMenu: undefined //will use desktop instead
};

export default configMenu;